import React from "react";
import "./index.css";
import App from "./App";
import ReactDOM from "react-dom";

const node = document.getElementById("root");

const Root = () => {
  return (
    <React.Fragment>
      <App />
    </React.Fragment>
  );
};

ReactDOM.render(<Root />, node);
