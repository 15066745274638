export const isDevelopment = !process.env.REACT_APP_BASE_URL.toLowerCase().includes("karamchalet");
export const ApiBaseUrl = process.env.REACT_APP_BASE_URL;
export const ApiTimeout = 5000;

export const printApiResponse = true;
export const printApiError = true;
export const printApiFinally = true;
export const printTodo = true;
export const printPageName = true;
export const printTabName = true;

// export const SENTRY_DNS_TOKEN = "https://7c9a0ac9145e4fae9d1ff5d6bf11cb26@o1094264.ingest.sentry.io/6215130";

// export const STORAGE_THEME_MODE = "STORAGE_THEME_MODE";
// export const STORAGE_LANGUAGE_MODE = "STORAGE_LANGUAGE_MODE";
// export const STORAGE_NOTIFICATION_TOKEN = "STORAGE_NOTIFICATION_TOKEN";
// export const STORAGE_AUTHORIZATION_TOKEN = "STORAGE_AUTHORIZATION_TOKEN";
