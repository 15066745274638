import React, { useContext } from "react";
import { Footer, Loading, TitleBar } from "Components";
import { AppContext } from "Contexts/AppContext/AppContext";
import { Icon } from "../../Components/Footer/Icon";

function ContactUsScreen() {
  const context = useContext(AppContext);
  const allChalets = context.getData();
  const contacts = context.getContactsData();

  if (allChalets) {
    return (
      <div style={{ flex: 1 }}>
        <TitleBar title="Contacts" />
        <div className="HomeScreenContainer">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              flex: "1",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <Contacts contacts={contacts} />
            <Instagram />
          </div>
        </div>
        <Footer whichChalet={allChalets?.[allChalets?.length - 1]?.key} />
      </div>
    );
  }
  return <Loading />;
}

export default ContactUsScreen;
const Contacts = ({ contacts }) => {
  return contacts.map((e, index) => {
    const formattedNumber = `${e.number.slice(0, 4)} ${e.number.slice(4)}`;

    return (
      <div
        key={index}
        style={{
          backgroundColor: "#0f3e68",
          display: "flex",
          padding: "20px",
          borderRadius: "20px",
          gap: "40px",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <p
          style={{
            fontSize: "20px",
            fontWeight: "bold",
            color: "white",
            margin: 0,
          }}
        >
          +965 {formattedNumber}
        </p>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          {e.isWhatsapp && (
            <Icon
              iconClass="fa fa-whatsapp"
              url={`https://wa.me/965${e.number}`}
            />
          )}
          {e.isCall && (
            <Icon iconClass="fa fa-phone-square" url={`tel:965${e.number}`} />
          )}
        </div>
      </div>
    );
  });
};
const Instagram = () => {
  return (
    <div
      style={{
        backgroundColor: "#0f3e68",
        display: "flex",
        padding: "20px",
        borderRadius: "20px",
        gap: "38px",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: "40px",
      }}
    >
      <p
        style={{
          fontSize: "20px",
          fontWeight: "bold",
          color: "white",
          margin: 0,
        }}
      >
        Visit us on instagram
      </p>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <Icon
          iconClass="fa fa-instagram"
          url="https://www.instagram.com/karamchalet/"
        />
      </div>
    </div>
  );
};
